import 'intersection-observer/intersection-observer';

function OverlayObserver(overlayTriggerList, options) {
  this.overlayTriggerList = overlayTriggerList;
  this.overlayListObserver = undefined;
  this.overlay = undefined;
  this.defaultOptions = {
    onIntersect: function () {},
    onLeave: function () {},
  };
  this.options = Object.assign({}, this.defaultOptions, options);
  this.observe();
}

OverlayObserver.prototype = {
  constructor: OverlayObserver,
  observe: function () {
    this.overlayListObserver = new IntersectionObserver(this.onIntersectionChange.bind(this), this.options);

    for (let i = 0; i < this.overlayTriggerList.length; i++) {
      this.overlayListObserver.observe(this.overlayTriggerList[i]);
    }
  },
  disconnect: function () {
    this.overlayListObserver.disconnect();
  },
  onIntersectionChange: function (triggerEntryList) {
    var overlayObserver = this;
    var triggerEntry = triggerEntryList[0];
    var dataOverlayValue = triggerEntry.target.getAttribute('data-overlay');
    this.overlay = document.querySelector('[data-overlay-trigger=' + dataOverlayValue + ']');

    if (!this.overlay) {
      this.disconnect();
      return;
    }

    if (triggerEntry.isIntersecting) {
      this.overlay.classList.remove('open');
      overlayObserver.options.onLeave.call();
    } else {
      this.overlay.classList.add('open');
      overlayObserver.options.onIntersect.call();
    }
  },
  getOverlay: function () {
    return this.overlay;
  },
};

export default OverlayObserver;
